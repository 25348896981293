import axios from 'axios';

export enum PizzaTopping {
    cheese = "cheese",
    pepperoni = "pepperoni",
    pineapple = "pineapple",
    sausage = "sausage",
    bacon = "bacon",
    mushrooms = "mushrooms",
    olives = "olives",
    peppers = "peppers",
}

const toPizzaTopping = (topping: string): PizzaTopping | undefined => {
    switch (topping.toLowerCase()) {
        case "pepperoni": return PizzaTopping.pepperoni;
        case "pineapple": return PizzaTopping.pineapple;
        case "sausage": return PizzaTopping.sausage;
        case "bacon": return PizzaTopping.bacon;
        case "mushrooms": return PizzaTopping.mushrooms;
        case "olives": return PizzaTopping.olives;
        case "black olives": return PizzaTopping.olives;
        case "green olives": return PizzaTopping.olives;
        case "peppers": return PizzaTopping.peppers;
        case "red peppers": return PizzaTopping.peppers;
        case "green peppers": return PizzaTopping.peppers;
        case "bell peppers": return PizzaTopping.peppers;
        case "red pepper": return PizzaTopping.peppers;
        case "green pepper": return PizzaTopping.peppers;
        case "bell pepper": return PizzaTopping.peppers;
        case "cheese": return PizzaTopping.cheese;
        default: return undefined;
    }
};

export interface ConversationMessage {
    role: string;
    message: string;
}

enum CallStatus {
    queued = "queued",
    ringing = "ringing",
    inprogress = "in-progress",
    forwarding = "forwarding",
    ended = "ended",
}

export class Conversation {
    id: string;
    phoneNumber: string;
    userWordCount: number = 0;
    botWordCount: number = 0;
    conversation: string = "";
    conversationForLLM: string = "";
    startedAt: string = "";
    updatedAt: string = "";
    status: CallStatus = CallStatus.queued;

    constructor(data: any) {
        this.id = data.id;
        this.phoneNumber = data.type === "inboundPhoneCall" ? data.customer.number : "Web Call";
        this.startedAt = data.startedAt;
        this.status = data.status;
        if (data.messages) {
            this.calculateWordCount(data.messages);
            this.calculateConversation(data.messages);
        }
    }

    private calculateConversation(messages: ConversationMessage[]) {
        for (const message of messages) {
            if (message.message.length > 0) {
                if (message.role === 'user') {
                    this.conversation += `Caller: ${message.message}\n\n`;
                    this.conversationForLLM += `Caller: ${message.message}\n\n`;
                } else if (message.role === 'bot') {
                    this.conversation += `Deepgram Aura: ${message.message}\n\n`;
                    this.conversationForLLM += `PizzaHut Employee: ${message.message}\n\n`;
                } else if (message.role === 'system') {
                    // This is the initial systemp prompt. Do nothing.
                } else {
                    console.log(message);
                }
            }
        }
    }

    private calculateWordCount(messages: ConversationMessage[]) {
        for (const message of messages) {
            const words = message.message.split(' ');
            if (message.role === 'user') {
                this.userWordCount += words.length;
            } else if (message.role === 'bot') {
                this.botWordCount += words.length;
            }
        }
    }
}

interface LatestConversationUpdate {
    npizzas: number;
    nwordsTranscribed: number;
    nwordsSpoken: number;
    latestPizzaUrl: string;
    latestTopToppings: PizzaTopping[];
    latestConversationId: string;
    latestConversationStatus: string;
    latestTranscript: string;
}

interface LatestTranscriptUpdate {
    transcript: string;
    id: string;
}

export class Backend {
    public numberOfPizzasOrdered: number;
    public numberOfWordsTranscribed: number;
    public numberOfWordsSpoken: number;
    public latestPizzaUrl: string;
    public latestTopToppings: (PizzaTopping | undefined)[];
    public latestTranscript: string;
    public latestConversationID: string;
    public latestConversationStatus: string;

    private apiHostname = "https://deepslice-api.deepgram.com";

    constructor() {
        this.latestTranscript = "";
        this.numberOfPizzasOrdered = 0;
        this.numberOfWordsTranscribed = 0;
        this.numberOfWordsSpoken = 0;
        this.latestPizzaUrl = "/pizza-without-background.png";
        this.latestTopToppings = [PizzaTopping.pepperoni, PizzaTopping.pineapple, PizzaTopping.olives, PizzaTopping.mushrooms];
        this.latestConversationID = "";
        this.latestConversationStatus = "";
    }

    public updateDashboard = async (): Promise<void> => {
        try {
            const response = (await axios.get(
                this.apiHostname + "/dashboard",
                {
                    headers: {
                        'accept': 'application/json',
                        'accept-language': 'en-US,en;q=0.9',
                        'x-api-key': 'fj958hvcakfj4jax',
                    },
                }
            )).data as LatestConversationUpdate;
            if (response !== undefined) {
                this.numberOfPizzasOrdered = response.npizzas;
                this.numberOfWordsTranscribed = response.nwordsTranscribed;
                this.numberOfWordsSpoken = response.nwordsSpoken;
                this.latestPizzaUrl = response.latestPizzaUrl;
                this.latestTopToppings = response.latestTopToppings.map(toPizzaTopping);
                this.latestTranscript = response.latestTranscript;
                this.latestConversationID = response.latestConversationId;
                this.latestConversationStatus = response.latestConversationStatus;
            }
            return;
        } catch (error) {
            console.error('Error fetching latest data:', error);
            throw error;
        }
    }

    public updateLatestTranscript = async (conversationId: string): Promise<LatestTranscriptUpdate> => {
        try {
            const response = await axios.get(
                this.apiHostname = `/latest-transcript/${conversationId}`,
                {
                    headers: {
                        'accept': 'application/json',
                        'accept-language': 'en-US,en;q=0.9',
                        'x-api-key': 'fj958hvcakfj4jax',
                    },
                }
            );
            return response.data;
        } catch (error) {
            console.error('Error fetching latest data:', error);
            throw error;
        }
    }
}


// Once every 5 seconds, look for new calls that were/are being made to Vapi
// Once every 0.5 seconds, pull the latest call's transcript if the call is in-progress
