import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Backend, PizzaTopping } from './services2';
import './App.css';

function numberWithCommas(x: number): string {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function toppingToNameAndImage(topping: PizzaTopping): [string, string] | undefined {
  switch (topping) {
    case PizzaTopping.cheese: return ["Cheese", "cheese.png"];
    case PizzaTopping.pepperoni: return ["Pepperoni", "pepperoni.png"];
    case PizzaTopping.pineapple: return ["Pineapple", "pineapple.png"];
    case PizzaTopping.sausage: return ["Sausage", "sausage.png"];
    case PizzaTopping.bacon: return ["Bacon", "bacon.png"];
    case PizzaTopping.mushrooms: return ["Mushrooms", "mushrooms.png"];
    case PizzaTopping.olives: return ["Black olives", "olives.png"];
    case PizzaTopping.peppers: return ["Peppers", "peppers.png"];
  }
}

const App: React.FC = () => {
  const conversationRef = useRef<HTMLDivElement>(null);
  const [pizzaImageUrl, setPizzaImageUrl] = useState<string>("/pizza-without-background.png");
  const backend = useMemo(() => new Backend(), []);  // creates the object one time
  const [topToppings, setTopToppings] = useState<PizzaTopping[]>([PizzaTopping.pepperoni, PizzaTopping.pineapple, PizzaTopping.olives, PizzaTopping.mushrooms]);
  const [numberOfPizzasOrdered, setNumberOfPizzasOrderd] = useState<number>(0);
  const [numberOfWordsTranscribed, setNumberOfWordsTranscribed] = useState<number>(0);
  const [numberOfWordsSpoken, setNumberOfWordsSpoken] = useState<number>(0);
  const [latestTranscript, setLatestTranscript] = useState<string>("");
  const [latestConversationId, setLatestConversationId] = useState<string>("");
  const [latestConversationStatus, setLatestConversationStatus] = useState<string>("");

  // Once every 5 seconds, look for new calls that were/are being made to Vapi
  // Once every 0.5 seconds, pull the latest call's transcript if the call is in-progress

  // Set up an interval that executes the backend code
  useEffect(() => {
    const updateDashboard = async () => {
      try {
        await backend.updateDashboard();
        setNumberOfPizzasOrderd(backend.numberOfPizzasOrdered);
        setNumberOfWordsTranscribed(backend.numberOfWordsTranscribed);
        setNumberOfWordsSpoken(backend.numberOfWordsSpoken);
        setLatestTranscript(backend.latestTranscript);
        setLatestConversationId(backend.latestConversationID);
        setLatestConversationStatus(backend.latestConversationStatus);
        setPizzaImageUrl(backend.latestPizzaUrl);
        setTopToppings(backend.latestTopToppings.filter((v) => v !== undefined) as PizzaTopping[]);
      } catch (error) {
        console.error('Error:', error);
      }
    };
    updateDashboard();
    const intervalId = setInterval(updateDashboard, 5000); // Run every X seconds

    // Clean up the interval on component unmount
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    const updateInProgressTranscript = async () => {
      if (latestConversationStatus === "in-progress") {
        try {
          const update = await backend.updateLatestTranscript(latestConversationId);
          if (update !== undefined && update.id === latestConversationId && update.transcript !== null) {
            setLatestTranscript(update.transcript);
          }
        } catch (error) {
          console.error('Error:', error);
        }
      };
    }
    updateInProgressTranscript();
    const intervalId = setInterval(updateInProgressTranscript, 500); // Run every X seconds

    // Clean up the interval on component unmount
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    const scrollInterval = setInterval(() => {
      if (conversationRef.current) {
        const scrollSpeed = 999999999999999;  // set to 1 to scroll slowly or a very high number to auto-scroll to the bottom
        conversationRef.current.scrollTop += scrollSpeed;
        if (conversationRef.current.scrollTop === conversationRef.current.scrollHeight) {
          conversationRef.current.scrollTop = 0;
        }
      }
    }, 50); // Adjust the scroll speed (interval) as needed

    return () => clearInterval(scrollInterval);
  }, []);

  return (
    <div className="container">
      <div className="logo-header">
        <img src="/deepgram-logo.png" className="deepgram-logo"></img>
        <span className="text-logo text-gradient text-deepslice">DeepSlice</span>
      </div>

      <div className="columns-wrapper">
        {/* First column */}
        <div className="column1">
          <div className="top-row">
            <div className="inner-column1 foreground-container">
              <span className="text text-header-size block">
                Latest pizza ordered
              </span>
              <img src={pizzaImageUrl} alt="Pizza" className="pizza-image block" />
              <span className="text text-gradient text-win-pizza-size heavy-weight block">
                Win a pizza by the end of the day!<br />
              </span>
              <span className="text text-phone-number-size block">
                Call +1 (415) 360 7536
              </span>
            </div>

            <div className="inner-column2 foreground-container">
              <div className=" top-toppings-text">
                <span className="text text-gradient text-header-size">NVIDIAs</span>
                <span className="text text-header-size">&nbsp;Top toppings</span>
              </div>
              <div className="fill-height toppings-grid">
                <div className="toppings-grid-row">
                  <div className="topping topping-1">
                    <img className="topping-image" src={(toppingToNameAndImage(topToppings[0]) || ["Pepperoni", "/pepperoni.png"])[1]}></img>
                    <span className="text text-body-size">{(toppingToNameAndImage(topToppings[0]) || ["Pepperoni", "/pepperoni.png"])[0]}</span>
                  </div>
                  <div className="topping topping-2">
                    <img className="topping-image" src={(toppingToNameAndImage(topToppings[1]) || ["Pineapple", "/pineapple.png"])[1]}></img>
                    <span className="text text-body-size">{(toppingToNameAndImage(topToppings[1]) || ["Pineapple", "/pineapple.png"])[0]}</span>
                  </div>
                </div>
                <div className="toppings-grid-row">
                  <div className="topping topping-3">
                    <img className="topping-image" src={(toppingToNameAndImage(topToppings[2]) || ["Black olives", "/olives.png"])[1]}></img>
                    <span className="text text-body-size">{(toppingToNameAndImage(topToppings[2]) || ["Black olives", "/olives.png"])[0]}</span>
                  </div>
                  <div className="topping">
                    <img className="topping-image" src={(toppingToNameAndImage(topToppings[3]) || ["Mushrooms", "/mushrooms.png"])[1]}></img>
                    <span className="text text-body-size">{(toppingToNameAndImage(topToppings[3]) || ["Mushrooms", "/mushrooms.png"])[0]}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bottom-row foreground-container">
            <div className="footer">
              <div className="footer-item footer-item-split">
                <div className="footer-number">{numberWithCommas(numberOfPizzasOrdered)}</div>
                <div className="text text-body-size">Pizzas Ordered</div>
              </div>
              <div className="footer-item footer-item-split">
                <div className="footer-number">{numberWithCommas(numberOfWordsTranscribed)}</div>
                <div className="text text-body-size">Words Transcribed</div>
              </div>
              <div className="footer-item">
                <div className="footer-number">{numberWithCommas(numberOfWordsSpoken)}</div>
                <div className="text text-body-size">Words Spoken</div>
              </div>
            </div>
          </div>
        </div>

        <div className="column2 foreground-container">
          <div className="text text-center text-header-size">Latest call</div>
          <div className="call" ref={conversationRef}>
            <span className="conversationText">
              {latestTranscript}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
